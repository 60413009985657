import { Spinner } from '@blueprintjs/core';
import React from 'react';
import styles from './loadingpage.module.css';

const LoadingPage: React.FC = () => (
  <div className={styles.root}>
    <Spinner intent="primary" size={64} />
    <p>Loading Page..</p>
  </div>
);

export default LoadingPage;
